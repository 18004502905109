import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function RamkyReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Ramky Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">We Make Dreams a Reality At Ramky Estates & Farms Limited, we constantly strive to create spaces with subtle designs that reverberate with warmth, comfort, and luxury. In the past 27 years, we have a proven track record of creating world-class projects encompassing the whole gamut of real estate covering residential, commercial, and integrated townships. To date, we have successfully delivered over 9 million sq.ft of residential and commercial projects; 13 million sq.ft of residential projects are under various stages of construction and 8 million sq.ft is ready for launch.
</p>

            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                    <h6>Vision</h6>
                <p  className="AboutExpoPara">To be a touchstone for customer delight and stakeholder value.
</p>
<h6>Mission</h6>
                <p  className="AboutExpoPara">To be a one-stop solution for all real estate needs offering a wide range of products and services across all markets while ensuring high-quality standards, delightful customer experience, fair business practices, and enhanced returns to stakeholders.
</p>




            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default RamkyReadMore;